<template>
  
  <div class="base">
    <Header/>

    <div class='2fa'>
        <Form />
    </div>
    
    <router-view/>
    <Footer/>
  </div>

</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Form from './components/Form.vue'

  export default {
    name: "App",
    components:{
      Header,
      Footer,
      Form
    }
  }
</script>

<style scoped>
  .base{
    padding: 20px;
    margin: 10px;
  }

  ul {
    list-style: none;
    display: inline-flex;
    justify-content: space-evenly;
  }

  #nav {
    border: 10px solid;
  }
  li{
    padding-right: 10px;
  }
</style>
