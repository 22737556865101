<template>
    <div>
        <div class='form-body'>
            <h3>2fa form</h3>
            <div class='form-div'>
                <ul>
                    <label class='select-label'>Find customer</label>
                        <select id='customer-select' class='select' v-model="selectedCustomer" @change="onChangeCustomer()">
                            <option value='' disabled>Select a customer</option>
                            <option
                                v-for="(customer, index) in listCustomers"
                                :key=index
                            >
                            {{`${customer.business_and_full_name}, CustomerID: ${customer.id}`}}
                            </option>
                        </select>
                </ul>

                <ul>
                    <label class='select-label'>Find Contact</label>
                        <select id='contact-select' class='select' v-model="selectedContact" @change="onChangeContact()" :disabled="listContacts.length == 0">
                            <option value="" disabled>Select a Contact</option>
                            <option
                                v-for="(contact, index) in listContacts.sort((a, b) => (a.name > b.name) ? 1 : -1)"
                                :key=index
                            >
                            {{`${contact.name}, Phone: ${contact.phone}, Email: ${contact.email} `}}
                            </option>
                        </select>
                </ul>
            </div>
        </div>

        <!-- v-show="this.ShowDiv" -->
        <div  v-show="this.ShowDiv" class='select-body'>
            <h3>Send them a message</h3>
            <div class="select-div">
                <ul>
                    <li>
                        Email: <strong>{{this.CustomerInfo.Email}}</strong>
                        <button @click="SendEmail()">Send Email</button>
                    </li>
                    <li>
                        Phone Number: <strong>{{this.CustomerInfo.SMS}}</strong>
                        <button @click="SendText()">Send Text Message</button>
                    </li>
                </ul>
            </div>
        </div>

        <div v-show="this.ShowNumber" class="number-body">
            <div class='number-div'>
                <h3>The number</h3>
                <p>{{this.RandomNumber}}</p>
                <button @click="this.ShowNumber = false; this.ShowDiv = true">Again</button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "Form",
    data() {
        return{
            listCustomers: [],
            listContacts: [],
            selectedContact: "",
            selectedCustomer: "",
            CustomerID: "",
            CustomerInfo: [],
            CompanyEmail: '',
            ShowDiv: false,
            ShowNumber: false,
            RandomNumber: 0,
        };
    },
    mounted() {
        this.LoadCustomers()
    },
    methods:{
        async LoadCustomers() {
            axios
                .get(`http://bluscreen.co.nz/customers`)
                .then((res) => {
                    this.listCustomers = res.data.sort((a, b) => (a.business_and_full_name > b.business_and_full_name) ? 1 : -1)
                })
        },
        async onChangeCustomer() {
            this.CustomerID = await this.selectedCustomer.split(',')[1].split(": ")[1];
            await axios
                .get(`https://miltec.syncromsp.com/api/v1/customers?id=${this.CustomerID}`,{
                        headers: {
                            Authorization: process.env.VUE_APP_TOKEN,
                            Accept: 'application/json'
                        }
                })
                .then((res) => {
                    this.CompanyEmail = res.data.customers[0].email.split("@")[1]
                })
                .catch((err) => {

                })
            

            await axios
                .get(`https://miltec.syncromsp.com/api/v1/contacts?customer_id=${this.CustomerID}`,{
                        headers: {
                            Authorization: process.env.VUE_APP_TOKEN,
                            Accept: 'application/json'
                        }
                })
                .then((res) => {
                    this.listContacts = res.data.contacts
                })
        },
        async onChangeContact() {
            var ContactArray = [];
            var ContactName = this.selectedContact.split(', ')[0]

            this.ShowDiv = false

            await axios
                    .get(`https://miltec.syncromsp.com/api/v1/contacts?customer_id=${this.CustomerID}`,{
                            headers: {
                                Authorization: process.env.VUE_APP_TOKEN,
                                Accept: 'application/json'
                            }
                    })
                    .then((res) => {
                        ContactArray = res.data.contacts
                    })

            ContactArray.forEach((Contact, i) => {
                if(Contact.name == ContactName){
                    var Firstname = Contact.name.split(' ')[0]
                    var Lastname =  Contact.name.split(' ')[1]


                    if(Contact.phone.startsWith('+64')){
                        //this code makes it an array joins all feilds together and then replaces the +64 with 0
                        Contact.phone = Contact.phone.replace('+64','0');
                    }
                    else if(!Contact.phone.startsWith('0')){
                        Contact.phone = "0"+Contact.phone
                    }

                    if(!Contact.email.includes(this.CompanyEmail)){
                        if(confirm(`this person's email is ${Contact.email}. this is not a company email would you like to change it to ${Firstname}@${this.CompanyEmail} `)){
                            Contact.email = `${Firstname}@${this.CompanyEmail}`
                        }
                        else{
                            var Prompt = prompt(`please enter the email you would to keep. (leave blank for ${Contact.email})`)

                            if(Prompt){
                                Contact.email = Prompt

                                
                            }
                        }

                    }

                    if(Lastname){
                        this.CustomerInfo = {
                        "name": Firstname,
                        "Lastname": Lastname,
                        "Email": Contact.email,
                        "SMS": Contact.phone.split(' ').join('')
                        }
                    }
                    else{
                        this.CustomerInfo = {
                        "name": Firstname,
                        "Email": Contact.email,
                        "SMS": Contact.phone.split(' ').join('')
                        }
                    }

                    this.ShowDiv = true
                }
            })
        },
        async SendEmail(){
            this.MakeRandomNumber()
            await axios.post(`http://bluscreen.co.nz/sendemail`, {
                CustomerInfo: this.CustomerInfo,
                RandomNumber: this.RandomNumber
            })
            alert("Succesfully sent Email")
            
            this.ShowDiv = false
            this.ShowNumber =  true
        },
        async SendText(){
            this.MakeRandomNumber()
            await axios.post(`http://bluscreen.co.nz/sendsms`, {
                   CustomerInfo: this.CustomerInfo,
                   RandomNumber: this.RandomNumber
                })
            alert("Succesfully sent text")
        
            this.ShowDiv =  false
            this.ShowNumber =  true

        },
        MakeRandomNumber(){
            this.RandomNumber = Math.floor(Math.random() * 90000) + 10000;
        }
    },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');

    div {
        font-family: 'Quicksand', sans-serif;
    }
    ul{
        list-style-type: none;
    }


    /* for Contact Select */
    .form-body{
        text-align:center; 
        vertical-align:middle;
    }
    .form-div{
        margin: auto;
    }    
    .select{
        margin-left: 5px;
    }
    #customer-select{
        width: 50%;
        
    }
    #contact-select{
        width: 50%;
    }

    /* For selecting message type */
    .select-body{
        text-align: center;
        vertical-align: middle;
    }

    .select-div{
        margin: auto;
    }

    /* For Random number */
    .number-body{
        text-align: center;
        vertical-align: middle;
    }

    .number-div{
        margin: auto;
    }

</style>