<template>
    <div class='Footer'>
        <div class='Left-footer'>
            <h1>Useful Links</h1>
                <ul>
                    <li><a href="https://remote.miltech.co.nz">Remote</a></li>
                    <li><a href="https://admin.microsoft.com">Microsoft Admin center</a></li>
                    <li><a href="https://partner.microsoft.com">Microsoft Partner Cetner</a></li>
                </ul>
        </div>

        <div class='Middle-footer'>
            <h1> Submit a issue</h1>
                <p>Most of u know me personally, but if we could please submit <a href="https://forms.gle/66YEv3UTyLuYCTFa6">here.</a> and just let me know :)</p>
        </div>
    </div>
</template>

<script>
 export default{
    name: "Footer"
 }
</script>

<style scoped>
    h1{
        text-align: center;
        font-size: 45;
        color: green;
        margin: 0px;
    }
    h2{
        text-align: center;
        font-size: 20;
        color: blue;
        margin: 0;
        padding-bottom: 30;
    }
    .Footer{
        border: 10px solid;
        margin: 10px;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        display: inline-flex;
        justify-content: space-evenly;
    }
</style>