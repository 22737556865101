<template>
    <div class='header'>
            <h1> Welcome to the Miltech 2FA</h1>
            <h2> Used for internal use only!! </h2>
    </div>
</template>

<script>
 export default{
    name: "Header"
 }
</script>

<style scoped>
    h1{
        text-align: center;
        font-size: 31;
        color: green;
        margin: 0px;
    }
    h2{
        text-align: center;
        font-size: 15;
        color: #07b836;
        margin: 0;
        padding-bottom: 30;
    }
    .header{
        margin: 10px;
    }
</style>